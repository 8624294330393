import React from "react";
import Layout from "../../layout";
import Library from "../../assets/images/library.jpg";
import RecentBlog from "./recent-blog";

const BlogPage = () => {
  return (
    <Layout>
      <section className="flex pt-40 flex-col md:grid md:grid-cols-12 px-5 pt-20 pb-10 gap-5 md:gap-20 bg-gray md:px-20 md:pt-40 pb-20">
        <div className="col-span-8">
          <main>
            <h1 className="main-font text-4xl mb-3">Lorem ipsum dolor sit amet consectetur</h1>
            <img src={Library} alt="pics" />
            <div className="my-5">
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime ipsum aliquid
                velit quidem rerum nam architecto delectus cupiditate dolores deleniti cum, asperiores ab repellendus
                illum, quia quam? Eius, quia perspiciatis distinctio numquam est eum quod. Veritatis in sapiente beatae
                ea velit aspernatur earum quis quaerat tempore consectetur, maiores necessitatibus deleniti quibusdam
                repellat porro quae molestias nostrum tempora! Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Nisi eos praesentium maxime ipsum aliquid velit quidem rerum nam architecto delectus cupiditate dolores
                deleniti cum, asperiores ab repellendus illum, quia quam? Eius, quia perspiciatis distinctio numquam est
                eum quod. Veritatis in sapiente beatae ea velit aspernatur earum quis quaerat tempore consectetur,
                maiores necessitatibus deleniti quibusdam repellat porro quae molestias nostrum tempora!Lorem ipsum
                dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime ipsum aliquid velit quidem
                rerum nam architecto delectus cupiditate dolores deleniti cum, asperiores ab repellendus illum, quia
                quam? Eius, quia perspiciatis distinctio numquam est eum quod. Veritatis in sapiente beatae ea velit
                aspernatur earum quis quaerat tempore consectetur, maiores necessitatibus deleniti quibusdam repellat
                porro quae molestias nostrum tempora!
              </p>
              <p className="my-2">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime ipsum aliquid
                velit quidem rerum nam architecto delectus cupiditate dolores deleniti cum, asperiores ab repellendus
                illum, quia quam? Eius, quia perspiciatis distinctio numquam est eum quod. Veritatis in sapiente beatae
                ea velit aspernatur earum quis quaerat tempore consectetur, maiores necessitatibus deleniti quibusdam
                repellat porro quae molestias nostrum tempora!
              </p>
              <h1 className="font-semibold">lorem quia quam? Eius delectus cupiditate dolores deleniti</h1>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime ipsum aliquid
                velit quidem rerum nam architecto delectus cupiditate dolores deleniti cum, asperiores ab repellendus
                illum, quia quam? Eius, quia perspiciatis distinctio numquam est eum quod. Veritatis in sapiente beatae
                ea velit aspernatur earum quis quaerat tempore consectetur, maiores necessitatibus deleniti quibusdam
                repellat porro quae molestias nostrum tempora!
              </p>
            </div>
          </main>
          <hr />

          <section className="my-3">
            <h2 className="font-semibold mt-10 mb-3">Leave a comment</h2>
            <div>
              <textarea
                id="message"
                name="message"
                placeholder="Enter Message"
                required
                rows={8}
                className="w-full h-full rounded rounded-lg  px-3 py-2 focus:outline-none"
              />
              <div className="float-right my-3">
                <button className="border rounded rounded-md border-primary p-2 hover:bg-primary hover:text-white">Submit</button>
              </div>
            </div>
          </section>
        </div>
        <main className="col-span-4">
          <div>
            <RecentBlog />
          </div>
        </main>
      </section>
    </Layout>
  );
};

export default BlogPage;
