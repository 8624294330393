"use client";
import React, { useState } from "react";
import AlbumList from "./album-list";
import Album from "./album";
import albumsData from "./utils/data";

const ImageSection = () => {
  const [selectedAlbumId, setSelectedAlbumId] = useState(null);

  const handleSelectAlbum = (album) => {
    setSelectedAlbumId(album.id);
  };

  const handleBackToGallery = () => {
    setSelectedAlbumId(null);
  };

  // Filter the selected album based on selectedAlbumId
  const selectedAlbum = albumsData.find((album) => album.id === selectedAlbumId);

  return (
    <div>
      <h1 className="main-font text-4xl mb-3">Gallery</h1>
      {selectedAlbumId === null ? (
        <AlbumList albums={albumsData} onSelectAlbum={handleSelectAlbum} />
      ) : (
        <Album album={selectedAlbum} onBackToGallery={handleBackToGallery} />
      )}
    </div>
  );
};

export default ImageSection;
