// data.js

const albums = [
  {
    id: 1,
    title: "BKC Stock",
    images: [
      { id: 1, url: "/gallery/bkc/bkc_teaching_01.jpg" },
      { id: 2, url: "/gallery/bkc/bkc_children_02.jpg" },
      { id: 3, url: "/gallery/bkc/bkc_stock_03.jpg" },
      { id: 4, url: "/gallery/bkc/bkc_banner_03.jpg" },
    ],
  },

  {
    id: 2,
    title: "Dummy Photos",
    images: [
      { id: 1, url: "gallery/dummy/classroom.jpg" },
      { id: 2, url: "gallery/dummy/gallery.jpg" },
      { id: 3, url: "gallery/dummy/hands.jpg" },
      { id: 4, url: "gallery/dummy/lamps.jpg" },
      { id: 5, url: "gallery/dummy/learn.jpg" },
      { id: 6, url: "gallery/dummy/library.jpg" },
      { id: 7, url: "gallery/dummy/pencils.jpg" },
      { id: 8, url: "gallery/dummy/swing.jpg" },
    ],
  },
];

export default albums;
