import React from "react";
import { ValueCard } from "../../components";
import { FaPeopleGroup } from "react-icons/fa6";
import { BiBuildingHouse } from "react-icons/bi";
import { SlBadge } from "react-icons/sl";
import { MdAllInclusive } from "react-icons/md";
import { HiOutlineShieldCheck } from "react-icons/hi";
import { PiHandFist } from "react-icons/pi";

const CoreValues = () => {
  const valueInfo = [
    {
      ic: <HiOutlineShieldCheck />,
      title: "Intergrity",
      text: "Upholding the highest standards of honesty and transparency in all activities",
    },
    {
      ic: <MdAllInclusive />,
      title: "Inclusivity",
      text: "Ensuring equal access to knwledge and resources for all individuals, regardless of background",
    },
    {
      ic: <FaPeopleGroup />,
      title: "Collaboration",
      text: "Fostering Partnership and team work to enhance learning and knowledge sharing",
    },
    {
      ic: <PiHandFist />,
      title: "Empowerment",
      text: "Enabling Individuals and communities to gain skills and knowledge to improve their lives",
    },
    { ic: <BiBuildingHouse/>, title: "Community", text: "Prioritizing the needs and wel-being of the community served" },
    {
      ic: <SlBadge />,
      title: "Excellence",
      text: "Striving for the highest quality in educational resources and services",
    },
  ];
  return (
    <main className="flex flex-col text-center justify-center items-center">
      <h1 className="main-font text-4xl">Core Values</h1>
      <p className="text-center font-semibold my-5 w-full md:w-8/12">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis laudantium, aspernatur qui ullam pariatur magni,
        corporis, veritatis expedita doloribus consequatur aut enim atque? Mollitia, consequatur velit magni beatae
        labore odio?
      </p>

      <div className="grid grid-cols-1 mt-3 gap-3 md:mt-8 text-black md:grid-cols-3 md:gap-10">
        {valueInfo.map(({ ic, text, title }) => (
          <div className="col-span-1">
            <ValueCard title={title} icon={ic} text={text} />
          </div>
        ))}
      </div>
    </main>
  );
};

export default CoreValues;
