import React, { useState } from "react";
import { motion } from "framer-motion";
import { NewsCard } from "../../components";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const BlogsAndEvents = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = window.innerWidth < 640; // Adjust breakpoint as needed
  const cardWidthPercentage = isMobile ? 100 : 25;

  const newsItems = [
    {
      title: "20th Graduation Ceremony",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic, expedita ex nam...",
      date: "2nd Feb 2024",
    },
    {
      title: "Scholars' Showcase: Celebrating Excellence in Education",
      //   text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic, expedita ex nam...",
      date: "2nd Feb 2024",
    },
    {
      title: "Academic Excellence Awards Night",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. expedita ex nam...",
      date: "2nd Feb 2024",
    },
    {
      title: "Learning Festival",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic, expedita ex nam...",
      date: "2nd Feb 2024",
    },
    {
      title: "Learning Festival",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic, expedita ex nam...",
      date: "2nd Feb 2024",
    },
    {
      title: "Learning Festival",
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic, expedita ex nam...",
      date: "2nd Feb 2024",
    },
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === newsItems.length - 4 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? newsItems.length - 4 : prevIndex - 1));
  };

  return (
    <div className="relative md:mx-10">
      <h1 className="main-font text-4xl text-primary mb-8">Blogs And Events</h1>

      <motion.div className="overflow-hidden">
        <motion.div
          className="flex"
          animate={{ x: `-${currentIndex * cardWidthPercentage}%` }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          {newsItems.map((item, index) => (
            <div key={index} className="flex-shrink-0 pr-3 md:pr-0 md:px-2 w-full sm:w-1/4">
              <NewsCard link="/blog-page" title={item.title} text={item.text} date={item.date} />
            </div>
          ))}
        </motion.div>
      </motion.div>

      <button onClick={prevSlide} className="absolute left-[-2rem] top-[10rem]  ">
        <MdArrowBackIos className="text-5xl hover:text-secondary text-primary" />
      </button>
      <button onClick={nextSlide} className="absolute right-[-2rem] top-[10rem] ">
        <MdArrowForwardIos className="text-5xl hover:text-secondary text-primary" />
      </button>
    </div>
  );
};

export default BlogsAndEvents;
