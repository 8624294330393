import { GoArrowUpRight } from "react-icons/go";
import React from "react";

const BackToTop = () => {
  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      onClick={handleClick}
      aria-label="Back to Top"
      className="flex py-3 px-4 rounded shadow-md z-[200] hover:bg-secondary hover:scale-105 transition-transform duration-100 fixed bottom-4 right-4 font-medium items-center text-white bg-primary text-t14"
    >
      <span className="hidden lg:block text-sm uppercase">Back to Top</span>
      <GoArrowUpRight size={20} />
    </button>
  );
};

export default BackToTop;
