import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Logo } from "../components";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

const Navigation = () => {
  const [openNav, setOpenNav] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleMenu = () => {
    setOpenNav(!openNav);
  };

  const nav = [
    { title: "Home", link: "/" },
    { title: "About Us", link: "/about" },
    { title: "Activities", link: "/activities" },
    { title: "Gallery", link: "/gallery" },
    { title: "Blog", link: "/blog" },
    { title: "Contact Us", link: "/contact" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <main>
      <section
        className={`fixed top-0 left-0 right-0 z-50 flex items-center justify-between bg-primary py-5 px-5 md:px-20 transition-colors duration-300 ${
          scrollPosition > 0 ? "bg-primary/80" : "bg-primary"
        }`}
      >
        <Logo />
        <nav className="hidden md:flex text-white">
          {nav.map(({ title, link }, index) => (
            <ul key={index} className="mx-5 cursor-pointer">
              <NavLink className="hover:text-secondary" to={link}>
                <li className="font-semibold">{title}</li>
              </NavLink>
            </ul>
          ))}
        </nav>

        <section className="lg:hidden">
          {openNav ? (
            <AiOutlineClose className="w-10 h-10 text-secondary" onClick={toggleMenu} />
          ) : (
            <BiMenuAltRight className="w-14 h-14 text-secondary" onClick={toggleMenu} />
          )}
        </section>
      </section>

      {/* Mobile view */}
      <div className={`fixed top-5 left-0 right-0 bottom-0 z-40 bg-secondary ${openNav ? "block" : "hidden"} pt-32`}>
        <section className="px-5">
          {nav.map(({ title, link }, index) => (
            <ul key={index} className="mx-5 cursor-pointer">
              <NavLink className="hover:text-primary main-font" to={link}>
                <li className="font-semibold text-6xl mb-10 text-gray hover:text-primary">{title}</li>
              </NavLink>
            </ul>
          ))}
        </section>
      </div>
    </main>
  );
};

export default Navigation;
