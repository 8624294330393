import Layout from "../layout";
import { HomeHero, Quote, SubSection, BlogsAndEvents, WhoWeAre, WhatWeDo, Faq } from "../sections";

const Home = () => {
  return (
    <Layout>
      <div>
        <HomeHero />
        <section className="bg-gray px-5 pt-20 pb-20 md:px-20 md:pt-40 md:pb-20">
          <SubSection />
        </section>
        <section className="bg-gray py-0 px-5 md:px-0 md:pr-20 md:py-20">
          <WhoWeAre />
        </section>
        <section className="bg-gray pt-32 px-5 md:px-0 md:pl-20 md:py-20">
          <WhatWeDo />
        </section>
        <section className="bg-gray px-5 md:px-20 py-20">
          <Quote />
        </section>
        <section className="px-10 md:px-20 pt-20 pb-40">
          <BlogsAndEvents />
        </section>
        <section className="bg-gray px-5 py-20 md:pb-20 md:px-20 ">
          <Faq />
        </section>
      </div>
    </Layout>
  );
};

export default Home;
