import Layout from "../layout";
import { HeroSection } from "../components";
import { ContactForm, ContactInfo, ContactMap } from "../sections";

const Contact = () => {
  return (
    <Layout>
      <HeroSection className="contact-bg" title="Contact Us" subTitle="Passion, Innovation, Virtue and Excellence" />
      <section className="grid md:grid-cols-12 px-5 pt-20 pb-10 items-center gap-5 md:gap-20 bg-gray md:px-20 md:pt-40 md:pb-20">
        <div className="col-span-6">
          <ContactInfo />
        </div>
        <div className="col-span-6">
          <ContactForm />
        </div>
      </section>
      <section className="bg-gray px-5 pb-20 pt-10 md:px-20 md:pb-40 md:pt-20">
        <ContactMap />
      </section>
    </Layout>
  );
};

export default Contact;
