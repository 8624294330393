import Layout from "../layout";
import { HeroSection } from "../components";
import { AboutUs, CoreValues, Faq, OurMission, OurPartners, OurTeam, OurVision } from "../sections";

const About = () => {
  return (
    <Layout>
      <HeroSection className="about-bg" title="Our Story" subTitle="Passion, Innovation, Virtue and Excellence" />
      <section className="bg-gray px-5 pt-20 pb-10 md:px-20 md:pt-40 md:pb-20">
        <AboutUs />
      </section>
      <section className="bg-gray px-5 md:px-0 md:pr-20 py-20">
        <OurMission />
      </section>
      <section className="bg-gray px-5 md:px-0 py-10 md:pl-20 md:py-20">
        <OurVision />
      </section>
      <section className="bg-gray px-5 md:px-20 py-20">
        <CoreValues />
      </section>
      <section className=" px-5 md:px-20 py-20">
        <OurPartners />
      </section>
      <section className="bg-gray px-5 pt-20 pb-20 md:pt-40 md:pb-20 md:px-20 ">
        <OurTeam />
      </section>
      <section className="bg-gray px-5 py-20 md:pb-20 md:px-20 ">
        <Faq />
      </section>
    </Layout>
  );
};

export default About;
