import { MdCall, MdOutlineMailOutline, MdOutlineLocationOn } from "react-icons/md";
import { FaLinkedinIn, FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";

const ContactInfo = () => {
  return (
    <div>
      <h1 className="main-font text-primary text-4xl">Get In Touch</h1>
      <p className="w-11/12 leading-tight my-5 font-semibold">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam possimus quo odio non quia blanditiis nesciunt
        libero ad eum, quos
      </p>

      <main>
        <section className="my-10 gap-10 md:gap-0 grid md:grid-cols-12">
          <div className="col-span-6 flex gap-3 items-center">
            <span className="bg-primary rounded p-2 rounded-lg">
              <MdCall className="text-3xl text-gray" />
            </span>
            <span className="leading-none">
              <h1 className="text-xl font-semibold">Phone</h1>
              <p className="text-medium text-sm">+234 701 2345 678</p>
            </span>
          </div>

          <div className="col-span-6 flex gap-3 items-center">
            <span className="bg-primary rounded p-2 rounded-lg">
              <MdOutlineMailOutline className="text-3xl text-gray" />
            </span>
            <span className="leading-none">
              <h1 className="text-xl font-semibold">Email</h1>
              <p className="text-medium text-sm">@example.com</p>
            </span>
          </div>
        </section>
        <section className="my-10 gap-10 md:gap-0 grid md:grid-cols-12">
          <div className="col-span-6 flex gap-3 items-center">
            <span className="bg-primary rounded p-2 rounded-lg">
              <MdOutlineLocationOn className="text-3xl text-gray" />
            </span>
            <span className="leading-none">
              <h1 className="text-xl font-semibold">Address</h1>
              <p className="text-none text-sm">
                3 Mallum Hamid Street, <br />
                Yola, Adamawa, Nigeria.
              </p>
            </span>
          </div>

          <div className="col-span-6 flex gap-3 items-center">
            <span className="bg-primary rounded p-2 rounded-lg">
              <FaLinkedinIn className="text-3xl text-gray" />
            </span>
            <span className="leading-none">
              <h1 className="text-xl font-semibold">LinkedIn</h1>
              <p className="text-sm">
                www.linkedin.com/in/ <br />
                dummy-profile/
              </p>
            </span>
          </div>
        </section>

        <hr className="w-full md:w-10/12" />

        <div className="my-5 flex items-center gap-5">
          <h1 className="main-font font-semibold">Social Media</h1>
          <div className="flex cursor-pointer">
            <FaFacebook className="text-2xl mx-1" />
            <FaTwitter className="text-2xl mx-1" />
            <FaYoutube className="text-2xl mx-1" />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ContactInfo;
