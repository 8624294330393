import React from "react";
import mission from "../../assets/images/hands.jpg";

const OurMission = () => {
  return (
    <section className="flex flex-col flex-col-reverse gap-10 md:flex-row md:items-center md:gap-20">
      <div className="h-full w-full md:h-[30rem] md:w-[50rem] overflow-hidden">
        <img className="h-full w-full object-cover" src={mission} alt="images" />
      </div>

      <div className="text-primary w-full md:w-5/12">
        <h1 className="main-font text-4xl">Our Mission</h1>
        <p className="font-semibold pb-5">
          Bindir Knowledge Center is a community which provides an excellent academic education in an environment of
          faith and virtue
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic,
          expedita ex nam consectetur nihil. Laboriosam vero amet quaerat nulla maiores esse dignissimos quibusdam
          libero. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid
          hic, expedita ex nam consectetur nihil. Laboriosam vero amet quaerat nulla maiores esse dignissimos quibusdam
          libero.
        </p>
      </div>
    </section>
  );
};

export default OurMission;
