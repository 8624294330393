import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import img1 from "../../assets/images/bkc_banner_03.jpg";
import img2 from "../../assets/images/classroom.jpg";
import img3 from "../../assets/images/learn.jpg";
import HeroButton from "./hero-button";

const carouselItems = [
  {
    id: 1,
    src: img1,
    alt: "Image 1",
    text: (
      <p>
        Faith, Community <br /> and Excellence
      </p>
    ),
    subText: <p>Education in an environment of faith and virtue of pre-K through 8th grade.</p>,
    btn: <HeroButton />,
  },
  {
    id: 2,
    src: img2,
    alt: "Image 2",
    text: (
      <p>
        Empowering <br /> Future Leaders
      </p>
    ),
    subText: (
      <p>Inspiring young minds to achieve their highest potential through a supportive and nurturing community.</p>
    ),
    btn: <HeroButton />,
  },
  {
    id: 3,
    src: img3,
    alt: "Image 3",
    text: (
      <p>
        A Tradition <br /> of Excellence
      </p>
    ),
    subText: (
      <p>Building on a legacy of academic success and personal growth with a commitment to excellence in all we do.</p>
    ),
    btn: <HeroButton />,
  },
];

const HomeHero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const variants = {
    enter: { opacity: 0, x: 50 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 },
  };

  return (
    <div className="relative top-24 mb-20 md:mb-10 flex items-center h-[35rem] justify-center w-full overflow-hidden">
      <motion.div
        key={currentIndex}
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        transition={{ duration: 0.5 }}
        className="relative w-full h-full flex items-center justify-center"
      >
        <img
          src={carouselItems[currentIndex].src}
          alt={carouselItems[currentIndex].alt}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute left-5 md:bottom-30 md:left-40 p-2 rounded-lg z-10">
          <p className="main-font text-4xl md:text-8xl leading-none text-white">{carouselItems[currentIndex].text}</p>
          <p className="main-font mr-5 md:mr-0 w-12/12 md:w-7/12 text-md pt-4 leading-tight text-white">
            {carouselItems[currentIndex].subText}
          </p>
          <div className="main-font mr-5 md:mr-0 text-sm pt-4 leading-tight text-white">
            {carouselItems[currentIndex].btn}
          </div>
        </div>
        <div className="absolute bottom-40 left-40 p-2 rounded-lg z-10"></div>
      </motion.div>

      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
        {carouselItems.map((_, index) => (
          <button
            key={index}
            onClick={() => handleDotClick(index)}
            className={`w-3 h-3 rounded-full ${currentIndex === index ? "bg-secondary" : "bg-gray"}`}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default HomeHero;
