import React, { useState } from 'react';
import { FaMagnifyingGlass } from 'react-icons/fa6';

const Search = ({ onSearch, placeholder}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  return (
    <div className="flex items-center border border-gray-300 rounded-md px-3 py-2">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        className="flex-grow bg-transparent focus:outline-none"
      />
      <div className="ml-2 text-gray-400">
        <FaMagnifyingGlass/>
      </div>
    </div>
  );
};

export default Search;