import { Link } from "react-router-dom";
import img1 from "../../assets/images/bkc_teaching_01.jpg";

const WhatWeDo = () => {
  return (
    <section className="flex flex-col gap-10 md:flex-row md:justify-between md:items-center md:gap-20">
      <div className="text-primary w-full md:w-5/12">
        <h1 className="main-font text-4xl">What We Do</h1>
        <p className="font-semibold pb-5">
          Bindir Knowledge Center is a community which provides an excellent academic education in an environment of
          faith and virtue
        </p>
        <main>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate accusamus numquam officia eaque
            deleniti laborum sed odit alias ea, aut ullam aperiam enim consectetur quos earum vitae. Tempore, illum
            fugit? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate accusamus numquam officia eaque
            deleniti laborum sed odit alias ea, aut ullam aperiam enim consectetur quos earum vitae. Tempore, illum
            fugit? Lorem, ipsum dolor sit amet consectetur adipisicing elit. Cupiditate accusamus numquam officia eaque
            deleniti laborum sed odit alias ea, aut ullam aperiam enim consectetur quos earum vitae. Tempore, illum
            fugit?
          </p>

          <button className="mt-10 p-3 hover:text-secondary hover:border-secondary border-primary border border-3xl">
            <Link to="/activities">Our Activities</Link>
          </button>
        </main>
      </div>
      <div className="h-full w-full md:h-[30rem] md:w-[45rem] flex justify-end overflow-hidden">
        <img className="h-full w-full object-cover" src={img1} alt="images" />
      </div>
    </section>
  );
};

export default WhatWeDo;
