import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <main className="mt-10 md:mt-0">
      <h1 className="md:hidden main-font text-primary text-4xl">Send Us A Message</h1>
      <div className="mt-5 md:mt-20 md:mt-0 py-5 px-6 bg-white rounded rounded-md shadow-md">
        <form onSubmit={handleSubmit}>
          <section className="grid gap-5 grid-cols-12">
            <div className="mb-4 col-span-6">
              <label className="block text-primary font-semibold" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full px-3 bg-gray py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                required
              />
            </div>
            <div className="mb-4 col-span-6">
              <label className="block text-primary font-semibold" htmlFor="email">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full px-3 bg-gray py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                required
              />
            </div>
          </section>

          <div className="mb-4">
            <label className="block ttext-primary font-semibold" htmlFor="phone">
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 bg-gray rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-primary font-semibold" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              className="w-full px-3 bg-gray py-2 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              rows="8"
              required
            />
          </div>
          <button
            type="submit"
            className="w-4/12 px-3 py-2 bg-primary text-white rounded-md hover:bg-secondary focus:outline-none focus:ring-1 focus:ring-blue-500"
          >
            Submit
          </button>
        </form>
      </div>
    </main>
  );
};

export default ContactForm;
