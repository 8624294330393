import { NavLink } from "react-router-dom";
import LogoImg from "../assets/images/bkc_logo.png";

const Logo = () => {
  return (
    <>
      <NavLink to="/" className="cursor-pointer">
        <header className="flex items-center">
          <img src={LogoImg} alt="logo" className="w-14 h-14" />
          <p className="logo cursor-pointer leading-none text-md text-white px-2 text-start md:text-center">
            BINDIR KNOWLEDGE <br /> CENTER
          </p>
        </header>
      </NavLink>
    </>
  );
};

export default Logo;
