import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About, Activities, Blog, Contact, Home, Gallery } from "../pages";
import { BlogPage } from "../sections";

const routes = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/activities" element={<Activities />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/blog-page" element={<BlogPage />} />
          <Route exact path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default routes;
