import { ImQuotesLeft } from "react-icons/im";
const Quote = () => {
  return (
    <div className="flex justify-center items-center flex-col md:mx-[25rem]">
      <div className="h-14 w-14 rounded-full border-secondary border border-5xl flex items-center justify-center">
        <ImQuotesLeft className="text-secondary text-2xl" />
      </div>

      <i className="main-font text-center pt-5 text-primary">
        "An investment in knowledge pays the best interest. Education is the key to unlocking the world, a passport to
        freedom."
      </i>
      <p className="pt-1 text-sm text-primary">— Benjamin Franklin</p>
    </div>
  );
};

export default Quote;
