import Layout from "../layout";
import { HeroSection } from "../components";
import { Faq, LongSessions, OurActivities, ShortSessions, WalkIns } from "../sections";

const Activities = () => {
  return (
    <Layout>
      <HeroSection
        className="activities-bg"
        title="Our Activities"
        subTitle="Passion, Innovation, Virtue and Excellence"
      />
      <section className="bg-gray px-5 pt-20 pb-10 md:px-20 md:pt-40 md:pb-20">
        <OurActivities />
      </section>
      <section className="bg-gray px-5 md:px-0 md:pr-20 py-20">
        <WalkIns />
      </section>
      <section className="bg-gray px-5 md:px-0 py-10 md:pl-20 md:py-20">
        <ShortSessions />
      </section>
      <section className="bg-gray px-5 md:px-0 md:pr-20 py-20">
        <LongSessions />
      </section>
      <section className="bg-gray px-5 py-20 md:pb-20 md:px-20 ">
        <Faq />
      </section>
    </Layout>
  );
};

export default Activities;
