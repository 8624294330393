import { FaAirbnb, FaGooglePay, FaReact } from "react-icons/fa";
import { FaMeta } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { UseMediaQuery } from "../../components";

function OurPartners() {
  const partnersInfo = [
    { logo: <FaMeta />, name: "Meta" },
    { logo: <FaAirbnb />, name: "Airbnb" },
    { logo: <FaReact />, name: "React" },
    { logo: <FaGooglePay />, name: "Google" },
    { logo: <FaReact />, name: "React" },
    { logo: <FaGooglePay />, name: "Google" },
    { logo: <FaMeta />, name: "Meta" },
    { logo: <FaAirbnb />, name: "Airbnb" },
  ];

  const isMobile = UseMediaQuery("(max-width: 768px)");

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 2 : 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 2500,
    cssEase: "linear",
  };
  return (
    <main>
      <div className="flex  justify-center items-center mb-10">
        <h1 className="main-font text-4xl">Our Partners</h1>
      </div>
      <div className="slider-container">
        <Slider {...settings}>
          {partnersInfo.map(({ logo, name }, index) => (
            <div className="flex flex-col justify-center items-center" key={index}>
              <div className="flex items-center justify-center">
                <div className=" text-7xl">{logo}</div>
              </div>
              <p className="text-center">{name}</p>
            </div>
          ))}
        </Slider>
      </div>
    </main>
  );
}

export default OurPartners;
