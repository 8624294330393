import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";

const SubCard = ({ img, text, link }) => {
  return (
    <div className="mb-10 md:mb-0">
      <div>
        <img className="h-full md:h-[15rem] w-full md:w-[20rem]" src={img} alt="images" />
      </div>
      
      <Link to={link} className="flex text-primary hover:text-secondary items-center gap-2 font-semibold">
        {text}
        <span>
          <MdArrowForwardIos />
        </span>
      </Link>
    </div>
  );
};

export default SubCard;
