import { Logo } from "../components";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare, FaRegCopyright, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { MdArrowForwardIos } from "react-icons/md";

const Footer = () => {
  const nav = [
    { title: "Home", link: "/" },
    { title: "About", link: "/about" },
    { title: "Activities", link: "/activities" },
    { title: "Gallery", link: "/gallery" },
    { title: "Blog", link: "/blog" },
    { title: "Contact", link: "/contact" },
  ];
  const social = [
    { icon: <FaFacebookSquare />, link: "#" },
    { icon: <FaInstagram />, link: "#" },
    { icon: <FaTwitter />, link: "#" },
    { icon: <FaLinkedin />, link: "#" },
  ];
  return (
    <footer className="footer-bg pb-32 pt-5 px-5 md:px-20 text-white">
      {/* Desktop footer */}
      <main className="hidden md:block">
        <main className="flex flex-row justify-between items-center">
          <section>
            <Logo />
          </section>

          <section>
            <nav className="flex text-white">
              {nav.map(({ title, link }, index) => {
                return (
                  <ul key={index} className="mx-5 cursor-pointer">
                    <NavLink className="hover:text-secondary" to={link}>
                      <li className="font-semibold">{title}</li>
                    </NavLink>
                  </ul>
                );
              })}
            </nav>
          </section>

          <section>
            <div className="flex items-center">
              {social.map(({ icon, link }, index) => {
                return (
                  <div key={index}>
                    <NavLink className="hover:text-secondary mx-8 text-2xl" to={link}>
                      {icon}
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </section>
        </main>

        <main className="flex flex-row mt-14 justify-between items-center">
          <section>
            <div>
              <p>3 Mallum Hamid Street</p>
              <p>Yola Adamawa, Nigeria</p>
              <p>Phone: (123) 456-7890</p>
              <p>Email: info@example.com</p>
            </div>
          </section>
          <section>
            <main className="flex items-center gap-5 justify-center">
              <h1 className="uppercase font-semibold">Subscribe now</h1>
              <div class="relative">
                <input
                  type="text"
                  class="border border-gray-300 rounded-l px-4 py-2 focus:outline-none focus:ring-2 focus:ring-secondary"
                  placeholder="@example.com"
                />
                <button class="bg-secondary text-white px-4 pt-3 pb-4 rounded-r focus:outline-none">
                  <MdArrowForwardIos />
                </button>
              </div>
            </main>
          </section>
        </main>

        <div className="py-5">
          <hr />
        </div>

        <section className="mt-1 flex text-sm justify-between">
          <div>
            <h1 className="flex items-center gap-1">
              <span>
                <FaRegCopyright />
              </span>
              2024 BINDIR KNOWLEDGE CENTER
            </h1>
          </div>

          <div className="flex gap-4 text-sm justify-between">
            <h1>PRIVACY POLICY</h1>
            <h1>TERMS OF USE</h1>
          </div>
        </section>
      </main>

      {/* mobil footer */}
      <main className="block md:hidden">
        <main className="flex justify-between items-center">
          <section>
            <Logo />
            <section className="my-4">
              <div>
                <p>3 Mallum Hamid Street</p>
                <p>Yola Adamawa, Nigeria</p>
                <p>Phone: (123) 456-7890</p>
                <p>Email: info@example.com</p>
              </div>
            </section>
          </section>

          <section>
            <div className="flex items-center">
              {social.map(({ icon, link }, index) => {
                return (
                  <div key={index}>
                    <NavLink className="hover:text-secondary mx-8 text-2xl" to={link}>
                      {icon}
                    </NavLink>
                  </div>
                );
              })}
            </div>
          </section>
        </main>

        <section>
          <nav className="flex mt-5 text-white">
            {nav.map(({ title, link }, index) => {
              return (
                <ul key={index} className="mx-2 cursor-pointer">
                  <NavLink className="hover:text-secondary" to={link}>
                    <li className="font-semibold">{title}</li>
                  </NavLink>
                </ul>
              );
            })}
          </nav>
        </section>

          <main className="flex mt-8 flex-col">
            <h1 className="uppercase font-semibold">Subscribe now</h1>
            <div class="relative ">
              <input
                type="text"
                className="border w-10/12 border-gray-300 rounded-l px-4 py-2 focus:outline-none focus:ring-2 focus:ring-secondary"
                placeholder="@example.com"
              />
              <button class="bg-secondary text-white px-4 pt-3 pb-4 rounded-r focus:outline-none">
                <MdArrowForwardIos />
              </button>
            </div>
          </main>
      
        <div className="py-5">
          <hr />
        </div>

        <section className="mt-1 flex text-sm justify-between">
          <div>
            <h1 className="flex items-center gap-1">
              <span>
                <FaRegCopyright />
              </span>
              2024 BINDIR KNOWLEDGE CENTER
            </h1>
          </div>

          <div className="flex gap-4 text-sm justify-between">
            <h1>PRIVACY POLICY</h1>
            <h1>TERMS OF USE</h1>
          </div>
        </section>
      </main>
    </footer>
  );
};

export default Footer;
