import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { Accordion } from "../../components";

const Faq = () => {
  const accordionItems = [
    {
      header: "Lorem ipsum dolor sit amet consectetur",
      content:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandae distinctio, fugiat fugit exrepudiandaedistinctio, fugiat fugit ex",
    },
    {
      header: "adipisicing elit. Dolor est rerum magnam porro",
      content:
        "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandaedistinctio, fugiat fugit ex Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandaedistinctio, fugiat fugit ex",
    },
    {
      header: "repudiandae distinctio,",
      content:
        "Lorem ipsum dolor sit amet consectetur, Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandaedistinctio, fugiat fugit ex adipisicing elit. Dolor est rerum magnam porro, repudiandaedistinctio, fugiat fugit ex",
    },
    {
      header: "Lorem ipsum dolor sit amet consectetur",
      content:
        "Lorem Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandae distinctio, fugiat fugit exipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandaedistinctio, fugiat fugit ex",
    },
  ];

  // const ref1 = useRef(null);
  const ref2 = useRef(null);
  // const isInView1 = useInView(ref1, { once: true });
  const isInView2 = useInView(ref2, { once: true });
  // const ref = useRef(null);
  // const inView = useInView(ref, { once: true });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, when: "beforeChildren", staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const MotionAccordionItem = ({ header, content }) => (
    <motion.div variants={itemVariants}>
      <Accordion items={[{ header, content }]} />
    </motion.div>
  );

  return (
    <main className="flex flex-col justify-center mb-40 items-center">
      <h1 className="main-font text-4xl mb-3 md:mb-10">FAQ</h1>
      <motion.div variants={itemVariants}>
        <p className="font-semibold  text-center">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolor est rerum magnam porro, repudiandae
          distinctio, fugiat fugit ex
        </p>
      </motion.div>

      <div className="w-full flex justify-center">
        <motion.section
          ref={ref2}
          initial="hidden"
          animate={isInView2 ? "visible" : "hidden"}
          variants={containerVariants}
          className=""
        >
          <motion.div className="mt-5" initial="hidden" animate="visible" variants={containerVariants}>
            {accordionItems.map((item, index) => (
              <MotionAccordionItem key={index} header={item.header} content={item.content} />
            ))}
          </motion.div>
        </motion.section>
      </div>
    </main>
  );
};

export default Faq;
