import { SubCard } from "../../components";
import img1 from "../../assets/images/bkc_stock_03.jpg";
import img2 from "../../assets/images/bkc_children_02.jpg";
import img3 from "../../assets/images/bkc_teaching_01.jpg";

const SubSection = () => {
  return (
    <section className="flex flex-col justify-center md:mx-24 md:items-center md:flex-row md:justify-evenly">
      <div>
        <SubCard img={img2} text="Who we are" />
      </div>
      <div>
        <SubCard img={img1} text="Academics" />
      </div>
      <div>
        <SubCard img={img3} text="Community" />
      </div>
    </section>
  );
};

export default SubSection;
