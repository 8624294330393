const ValueCard = ({ icon, title, text }) => {
  return (
    <div className="bg-primary text-gray md:w-[350px] md:h-[250px] cursor-pointer p-5">
      <div className="bg-gray h-14 w-14 rounded rounded-full flex justify-center items-center text-3xl text-primary">{icon}</div>
      <h2 className="text-md font-semibold mt-5 text-left">{title}</h2>
      <p className="text-md text-left">{text}</p>
    </div>
  );
};

export default ValueCard;
