import { MdArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";

const BlogCard = ({ img, text, title, date, link }) => {
  return (
    <div className="mb-10 shadow bg-white h-[450px] shadow-lg pb-5 md:mb-0">
      <div>
        <img className="h-[18rem] w-full" src={img} alt="images" />
      </div>

      <section className=" text-primary p-5">
        <main>
          <p className="text-sm text-[#b2bed5] font-semibold">{date}</p>
          <h1 className="font-semibold text-md">{title}</h1>
          <p className="text-sm leading-none">{text}</p>
        </main>

        <main className="mt-5 float-right">
          <Link
            to="/blog-page"
            className="flex text-xs text-primary hover:text-secondary items-center gap-1 font-semibold"
          >
            <p className="uppercase">Read More</p>
            <span>
              <MdArrowForwardIos />
            </span>
          </Link>
        </main>
      </section>
    </div>
  );
};

export default BlogCard;
