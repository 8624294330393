import Layout from "../layout";
import { HeroSection } from "../components";
import { GallerySection } from "../sections";

const Gallery = () => {
  return (
    <Layout>
      <HeroSection className="gallery-bg" title="Gallery" subTitle="Passion, Innovation, Virtue and Excellence" />
      <section className="bg-gray px-5 md:px-20 pt-20 md:pt-40 pb-20">
       <GallerySection/>
      </section>
    </Layout>
  );
};

export default Gallery;
