const HeroSection = ({ className, title, subTitle  }) => {
  return (
    <div className={`relative top-24 h-[36rem] w-full mb-20 md:mb-10 bg-cover bg-center ${className}`}>
      <div className="absolute inset-0 bg-black opacity-60"></div>
      <div className="main-font relative bottom-[-10rem] ml-5 md:ml-20 h-full text-white">
        <h1 className="text-7xl md:text-8xl font-bold pt-10">{title}</h1>
        <p className="text-sm">{subTitle}</p>
      </div>
    </div>
  );
};

export default HeroSection;