import Layout from "../layout";
import { HeroSection, Search } from "../components";
import { BlogSection, RecentBlog } from "../sections";

const Blog = () => {
  return (
    <Layout>
      <HeroSection className="about-bg" title="Keep Up With Us" subTitle="Passion, Innovation, Virtue and Excellence" />

      <main className="bg-gray px-5 pt-20 pb-40 md:px-20 md:pt-40 md:pb-20">
        <div className="mb-10 md:w-3/12">
          <Search placeholder="Search blogs" />
        </div>

        <section className="flex flex-col-reverse md:grid md:grid-cols-12  gap-5 md:gap-20 ">
          <main className="col-span-4">
            <div>
              <RecentBlog />
            </div>
          </main>
          <div className="col-span-8">
            <BlogSection />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default Blog;
