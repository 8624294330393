import { Link } from "react-router-dom";
import img1 from "../../assets/images/bkc_children_02.jpg";

const WhoWeAre = () => {
  return (
    <section className="flex flex-col flex-col-reverse items-center gap-20 md:gap-40 md:flex-row">
      <div className="md:h-[30rem] md:w-[40rem] overflow-hidden">
        <img className="h-full w-full object-cover" src={img1} alt="images" />
      </div>

      <div className="text-primary w-full md:w-4/12">
        <h1 className="main-font text-4xl">Who We Are</h1>
        <p className="font-semibold pb-5">
          Bindir Knowledge Center is a community which provides an excellent academic education in an environment of
          faith and virtue
        </p>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid hic,
          expedita ex nam consectetur nihil. Laboriosam vero amet quaerat nulla maiores esse dignissimos quibusdam
          libero. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Neque animi incidunt tempora odit aliquid
          hic, expedita ex nam consectetur nihil. Laboriosam vero amet quaerat nulla maiores esse dignissimos quibusdam
          libero.
        </p>

        <button className="mt-10 p-3 hover:text-secondary hover:border-secondary border-primary border border-3xl">
          <Link to="/about">About Us</Link>
        </button>
      </div>
    </section>
  );
};

export default WhoWeAre;
