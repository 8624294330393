import { BsDot } from "react-icons/bs";
import vision from "../../assets/images/lamps.jpg";

const OurVisions = () => {
  const visionData = [
    {
      text: "sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam",
    },
    {
      text: "Lorem  elit. Eaque doloremque voluptatem inventore totam numquam Eaque doloremque voluptatem ",
    },
    {
      text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam",
    },
    {
      text: " ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatm",
    },
  ];

  return (
    <section className="flex flex-col gap-10 md:flex-row md:justify-between md:items-center md:gap-20">
      <div className="text-primary w-full md:w-5/12">
        <h1 className="main-font text-4xl">Our Vision</h1>
        <p className="font-semibold pb-5">
          Bindir Knowledge Center is a community which provides an excellent academic education in an environment of
          faith and virtue
        </p>
        <main>
          {visionData.map(({ text }) => (
            <main className="grid mt-2 grid-cols-8">
              <BsDot className="text-5xl col-span-1" />
              <p className="col-span-7">{text}</p>
            </main>
          ))}
        </main>
      </div>
      <div className="h-full w-full md:h-[30rem] md:w-[45rem] flex justify-end overflow-hidden">
        <img className="h-full w-full object-cover" src={vision} alt="images" />
      </div>
    </section>
  );
};

export default OurVisions;
