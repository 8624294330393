import React from "react";
import Image from "./Image";
import { FaImage } from "react-icons/fa";
import { UseMediaQuery } from "../../components";


const AlbumList = ({ albums, onSelectAlbum }) => {
  const isMobile = UseMediaQuery("(max-width: 768px)");
  const imageStyle = {
    height: isMobile ? "20rem" : "40rem",
    width: isMobile ? "30rem" : "50rem",
  };

  const subImg = {
    height: "8rem",
    objectFit: "cover",
  };

  return (
    <div className="w-full">
      <ul className="md:pt-0 md:grid md:grid-cols-2 md:gap-4 ">
        {albums.map((album) => (
          <div>
            <li key={album.id} className="mt-10">
              <button onClick={() => onSelectAlbum(album)} className="relative group">
                <div className="pb-3">
                  <Image style={imageStyle} key={album.images[0].id} image={album.images[0]} />
                </div>
                <div className="flex gap-2 justify-around">
                  {album.images.slice(1, 5).map((image) => (
                    <div>
                      <Image style={subImg} key={image.id} image={image} />
                    </div>
                  ))}
                </div>

                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-75 flex items-center justify-center transition-opacity">
                  <span className="text-white text-lg">Open Album</span>
                </div>
              </button>
              <main className="flex justify-between  font-bold">
                <div>{album.title}</div>
                <div className="flex items-center gap-2">
                  <FaImage className="text-primary" />
                  {album.images.length}
                </div>
              </main>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AlbumList;
