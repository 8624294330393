import { Link } from "react-router-dom";

const NewsCard = ({ link, title, text, date }) => {
  return (
    <Link to={link}>
      <div className="relative hover:bg-primary bg-gray hover:text-gray cursor-pointer text-primary h-[14rem] w-full md:w-[18rem] p-5">
        <h1 className="main-font text-2xl">{title}</h1>
        <p className="py-3 text-sm">{text}</p>
        <p className="absolute bottom-3 text-xs right-5">{date}</p>
      </div>
    </Link>
  );
};

export default NewsCard;
