import React from "react";

const AboutUs = () => {
  return (
    <main className="flex flex-col text-center mx-0 md:mx-40 justify-center items-center">
      <h1 className="main-font text-4xl">About Us</h1>
      <p className="font-semibold mt-1 mb-3">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam,
        deserunt qui aliquid.
      </p>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam,
        deserunt qui aliquid. Non sint, perferendis a expedita quae, iusto magni eum dolorum, veniam voluptate quas.
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam,
        deserunt qui aliquid. Non sint, perferendis a expedita quae, iusto magni eum dolorum, veniam voluptate quas.
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam,
        deserunt qui aliquid. Non sint, perferendis a expedita quae, iusto magni eum dolorum, veniam voluptate quas.
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eaque doloremque voluptatem inventore totam numquam,
        deserunt qui aliquid. Non sint, perferendis a expedita quae, iusto magni eum dolorum, veniam voluptate quas.
      </p>
    </main>
  );
};

export default AboutUs;
