import React from "react";
import Image from "./Image";

const ImageList = ({ images }) => {
  return (
    <div>
      <ul className="md:grid md:grid-cols-3 md:gap-2 my-4">
        {images.map((image) => (
          <li key={image.id} className="py-2">
            <Image image={image} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ImageList;
