import React from "react";
import Nav from "./navigation";
import Footer from "./footer";
import { BackToTop } from "../components";

const Layout = ({ children }) => {
  return (
    <div>
      <Nav />
      <div>{children}</div>
      <Footer />
      <BackToTop />
    </div>
  );
};

export default Layout;
