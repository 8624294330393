import React from "react";

const ContactMap = () => {
  return (
    <div>
      <h1 className="main-font text-4xl mb-5">Visit Us</h1>
      <iframe
        title="frame"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6288.947208847739!2d12.471185849999999!3d9.205198800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10535e9d85a26b2d%3A0x10efb2a9a2dbf6f8!2s3%20Mallum%20Hamid%20Street%2C%20Jimeta%20940241%2C%20Yola!5e0!3m2!1sen!2sng!4v1623258404148!5m2!1sen!2sng"
        width="600"
        height="450"
        style={{ width: "100%" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default ContactMap;
