import React from "react";
import ImageList from "./image-list";
import { BsArrowLeft } from "react-icons/bs";

const Album = ({ album, onBackToGallery }) => {
  return (
    <main>
      <div className="flex justify-between items-center">
        <h2 className="text-primary font-bold">{album.title}</h2>

        <button
          className="bg-secondary text-white p-2 flex gap-2 items-center"
          onClick={onBackToGallery}
        >
          <BsArrowLeft />
          Back to Gallery
        </button>
      </div>

      <ImageList images={album.images} />
    </main>
  );
};

export default Album;
