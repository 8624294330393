import { BsDot } from "react-icons/bs";
import { RiNumber1, RiNumber2, RiNumber3, RiNumber4, RiNumber5 } from "react-icons/ri";

const RecentBlog = () => {
  const recentTopic = [
    {
      icon: <RiNumber1 />,
      topic: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      date: "30th-July-2024",
      author: "Yahya Bindir",
    },
    {
      icon: <RiNumber2 />,
      topic: "Eaque doloremque voluptatem inventore totam numquam",
      date: "30th-July-2024",
      author: "Yahya Bindir",
    },
    { icon: <RiNumber3 />, topic: "deserunt qui aliquid. Non sint, ", date: "30th-July-2024", author: "Yahya Bindir" },
    {
      icon: <RiNumber4 />,
      topic: "perferendis a expedita quae, iusto magni eum dolorum,",
      date: "30th-July-2024",
      author: "Yahya Bindir",
    },
    {
      icon: <RiNumber5 />,
      topic: "magni eum dolorum, veniam voluptate quas",
      date: "30th-July-2024",
      author: "Yahya Bindir",
    },
  ];
  return (
    <main>
      <h1 className="main-font text-4xl mb-3">Recent Posts</h1>
      <section className="bg-white cursor-pointer rounded shadow shadow-lg p-5 ">
        <section>
          {recentTopic.map(({ icon, topic, date, author }) => (
            <div className="flex my-5 items-start gap-5">
              <div className="text-2xl font-bold my-1">{icon}</div>
              <div>
                <p className="font-semibold leading-none">{topic}</p>
                <span className="flex font-semibold text-[#b2bed5] items-center text-sm">
                  <p> {date}</p>
                  <BsDot className=" text-3xl" />
                  <p>{author}</p>
                </span>
              </div>
            </div>
          ))}
        </section>
      </section>
    </main>
  );
};

export default RecentBlog;
