import Routes from './routes/route'

function App() {
  return (
    <div>
      <Routes/>
    </div>
  );
}

export default App;

