import Girl from "../../assets/images/girl.jpg";

const OurTeam = () => {
  return (
    <main className="flex flex-col justify-center items-center">
      <h1 className="main-font text-4xl mb-10">Our Team</h1>
      <section className="grid grid-cols-1 gap-10 md:gap-20 md:grid-cols-12">
        <div className=" col-span-3">
          <img className="  object-contain" src={Girl} alt="girl" />
          <div className="leading-snug">
            <p className="font-semibold text-xl">Headmaster</p>
            <p>John Doe</p>
          </div>
        </div>
        <div className=" col-span-3">
          <img className="  object-contain" src={Girl} alt="Girl" />
          <div className="leading-snug">
            <p className="font-semibold text-xl">Vice Headmaster</p>
            <p>John Doe</p>
          </div>
        </div>
        <div className="  col-span-3">
          <img className="  object-contain" src={Girl} alt="girl" />
          <div className="leading-snug">
            <p className="font-semibold text-xl">Accountant</p>
            <p>John Doe</p>
          </div>
        </div>
        <div className=" col-span-3">
          <img className="  object-contain" src={Girl} alt="Girl" />
          <div className="leading-snug">
            <p className="font-semibold text-xl">Teacher</p>
            <p>John Doe</p>
          </div>
        </div>
      </section>
    </main>
  );
};

export default OurTeam;
