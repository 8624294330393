import React, { useState } from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { BsArrowUpRight } from "react-icons/bs";
import { motion, AnimatePresence } from "framer-motion";

const CustomAccordion = ({ items, color = "#072969" }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionChange = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <Accordion>
      {items.map((item, index) => (
        <AccordionItem
          className="w-full md:w-[1000px]"
          key={index}
          header={
            <div
              className="py-5 flex w-[390px] md:w-[1000px] items-center justify-between"
              onClick={() => handleAccordionChange(index)}
              style={{ color }}
            >
              <h1 className="font-semibold">{item.header}</h1>
              <BsArrowUpRight className={`font-semibold arrow-icon  ${activeIndex === index ? "rotate-90" : ""}`} />
            </div>
          }
        >
          <AnimatePresence initial={false}>
            {activeIndex === index && (
              <motion.div
                className="mb-10"
                style={{ color }}
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {item.content}
              </motion.div>
            )}
          </AnimatePresence>
        </AccordionItem>
      ))}
      <hr />
    </Accordion>
  );
};

export default CustomAccordion;
