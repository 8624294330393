// import { SlBadge } from "react-icons/sl";
import { IoArrowForward } from "react-icons/io5";
import { BiDonateHeart } from "react-icons/bi";
import { PiGraduationCapBold } from "react-icons/pi";

const HeroButton = () => {
  return (
    <main className="flex flex-col md:flex-row w-12/12 gap-3 md:gap-1">
      {/* <div className="relative py-5 px-5">
        <div className="absolute inset-0 bg-primary opacity-70"></div>
        <main className="flex items-center gap-2 cursor-pointer">
          <SlBadge className="relative text-5xl text-white" />
          <div>
            <div className="relative text-xl text-white uppercase">Inspiring Excellence</div>
            <p className="relative flex gap-2 items-center text-white font-light text-sm">
              Apply
              <span>
                <IoArrowForward />
              </span>
            </p>
          </div>
        </main>
      </div> */}
      <div className="relative py-5 px-5 md:px-10">
        <div className="absolute inset-0 bg-primary opacity-70"></div>
        <main className="flex items-center gap-2 cursor-pointer">
          <PiGraduationCapBold className="relative text-3xl md:text-5xl text-white" />
          <div>
            <div className="relative text-lg md:text-xl text-white uppercase">Learning Meets Growth</div>
            <p className="relative flex gap-2 items-center text-white font-light text-sm">
              Visit
              <span>
                <IoArrowForward />
              </span>
            </p>
          </div>
        </main>
      </div>
      <div className="relative py-5 px-5 md:px-10">
        <div className="absolute inset-0 bg-primary 0 opacity-70"></div>
        <main className="flex items-center gap-2 cursor-pointer">
          <BiDonateHeart className="relative text-3xl md:text-5xl text-white" />
          <div>
            <div className="relative text-lg md:text-xl text-white uppercase">Empowering Futures</div>
            <p className="relative flex gap-2 items-center text-white font-light text-sm">
              Donate
              <span>
                <IoArrowForward />
              </span>
            </p>
          </div>
        </main>
      </div>
    </main>
  );
};

export default HeroButton;
