import React from "react";
import { BlogCard } from "../../components";
import Library from "../../assets/images/library.jpg";
import Lamps from "../../assets/images/lamps.jpg";
import Learn from "../../assets/images/learn.jpg";
import Pencils from "../../assets/images/pencils.jpg";
import Swings from "../../assets/images/swing.jpg";
import Classroom from "../../assets/images/classroom.jpg";

const BlogSection = () => {
  const blogs = [
    {
      img: Library,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
    {
      img: Lamps,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
    {
      img: Learn,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
    {
      img: Classroom,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
    {
      img: Swings,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
    {
      img: Pencils,
      date: "Apr 20th 2024",
      title: "Lorem ipsum dolor sit",
      text: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nisi eos praesentium maxime",
    },
  ];
  return (
    <main>
      <h1 className="main-font text-4xl mb-3">Blogs</h1>
      <section className="grid grid-cols-1 mt-3 gap-3 text-black md:grid-cols-2 md:gap-10">
        {blogs.map(({ img, text, date, title }) => (
          <div className="col-span-1">
            <BlogCard date={date} title={title} img={img} text={text} />
          </div>
        ))}
      </section>
    </main>
  );
};

export default BlogSection;
